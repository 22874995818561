import {Component, ComponentType, ErrorInfo, ReactNode} from 'react';
import {PropsWithRequiredChildren} from '../react/types';
import {ErrorCard, ErrorProps} from './ErrorCard';

type ErrorBoundaryState = Partial<ErrorProps>;

export interface ErrorBoundaryProps extends PropsWithRequiredChildren {
  context?: string;
}
export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    error: undefined,
    errorInfo: undefined,
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo);
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render(): ReactNode {
    const {error, errorInfo} = this.state;
    // eslint-disable-next-line react/prop-types
    const {children, context} = this.props; // react/prop-types is incorrectly flagging this for some reason
    if (error) {
      return <ErrorCard context={context} error={error} errorInfo={errorInfo} />;
    }
    return children;
  }
}

export function withErrorBoundary<P extends object>(Component: ComponentType<P>): ComponentType<P> {
  const WrappedComponent = (props: P) => (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );
  WrappedComponent.displayName = getDisplayName(Component);
  return WrappedComponent;
}

function getDisplayName<P extends object>(Component: ComponentType<P>) {
  return Component.displayName || Component.name || 'Component';
}
